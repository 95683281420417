import React, { useState, useRef } from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import reactArray from '../components/reactArray'

function ExportCsv() {

    const [doExport, setDoExport] = useState(false); // Trigger: Changing this makes the component render. 
                                                     // It does NOT control whether the export actually happens.
    const ref_doExport = useRef(false);              // This does!

    const handleExportClick = (e) => {
        e.preventDefault();
        ref_doExport.current = true;
        setDoExport(!doExport);  
    }

    const isExport = () => ref_doExport.current == true;

    const handleOnData = (res) => {

        console.log("ExportCsv: handleOnData :: ref_doExport.current = " + ref_doExport.current +
            ",  res.length = " + res.length);

        if (res.length !== 0) {

            // ID-27 "Warning: Cannot update a component (`ExportCsv`) while rendering a different component (`Results`)"
            // setDoExport(false);  

            // ID-27 Using a ref (instead of state) removes the warning
            ref_doExport.current = false;
            Csv(res);
        }
    }

    const Csv = (json) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.REACT_APP_CSV_API_URL, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            var blob = this.response;
            var contentDispo = xhr.getResponseHeader('Content-Disposition');
            var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
        }
        xhr.send(new Blob([JSON.stringify(json)], { type: 'application/json' }));

    }

    //Loader er fjernet men kan genindsættes.
    return (
        <React.Fragment>
            <button type="button" title="Eksporter dit søgeresultat til CSV " className="btn btn-sm btn-light btn-outline-dark me-3" onClick={(e) => handleExportClick(e)}>Eksporter</button>

            {isExport() &&

                <ReactiveList
                    componentId="ExportCsvList"
                    dataField="EntityName"
                    showResultStats={false}
                    size={5000}
                    loader={/*<div className="btn btn-sm btn-light me-3">Eksporterer til CSV</div>*/<div></div>}
                    react={{
                        and: reactArray("ExportCsvList")
                    }}
                    render={({
                        loading,
                        error,
                        data,
                    }) => handleOnData(data)}
                />
            }

        </React.Fragment>
    )
}

export default ExportCsv
