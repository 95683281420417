import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch'
import StaticTree from './StaticTree';
import Fields from '../components/Fields'
import '../treesearch.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight, faCaretDown, faCircle, faToggleOff, faToggleOn)

/*This component renders the search results in a hierarchical tree structure. 
The entities results is rendered in the Dom as unordered lists inside unordered lists.*/

function TreeSearch(props) {

    /* Function that returns a CSS class that highlights the selected entity */
    const selectedOrNot = (itemId) => {
        return itemId === props.selectedEntity ? "sor-selected" : "sor-not-selected";
    }

    const defaultQueryReactiveTreeSearch = () => {
        return {
            "query": {
                "match": {
                    "SorType.keyword": "IE"
                }
            },
            "size": 0,
            "aggs": {
                "group_by_region": {
                    "terms": {
                        "field": "EntityTypeName.keyword",
                        "missing": "N/A"
                    },
                    "aggs": {
                        "group_by_postal": {
                            "terms": {
                                "field": "PostalMunicipalityName.keyword",
                                "missing": "N/A",
                                "size": 300
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div className="col-12 mb-3">
                <div className="justify-content-end" style={{ display: 'flex'}}>
                    <div className="btn-group-toggle me-3 filters-toggle" data-toggle="buttons">
                        <label className="btn btn-sm btn-outline-light active" title="Vis søgefiltre" onClick={props.handleToggleFilters}>
                            <input class="with-font-awesome-icon" type="checkbox" />
                            <FontAwesomeIcon icon={props.showFilters ? "toggle-on" : "toggle-off"} />
                        </label>
                    </div>
                    <div>
                        {/*<button type="button" className="btn btn-sm btn-light btn-outline-dark me-3" data-toggle="tooltip" data-placement="right" title="Eksporter din søgning til CSV fil">Eksporter</button>*/}
                    </div>
                    <Fields showFields={props.showFields} handleShowFieldsClick={props.handleShowFieldsClick} />
                </div>
            </div>

            {props.pathname === "/tree" &&
                <ReactiveComponent
                    componentId="ReactiveTreeSearch"
                    defaultQuery={defaultQueryReactiveTreeSearch}
                    react={{
                        and: ["TodataSearch", "InstitutionOwnerEntityNameSearch"]
                    }}
                    render={({ aggregations, setQuery }) => (
                        <StaticTree
                            selectedOrNot={selectedOrNot}
                            handleClick={props.handleClick}
                            showFields={props.showFields}
                            showEntityTypeName={props.showEntityTypeName}
                            showTreeList={props.showTreeList}
                            setShowTreeList={props.setShowTreeList}
                            showFirstLetter={props.showFirstLetter}
                            showSorId={props.showSorId}
                            aggregations={aggregations}
                            setQuery={setQuery}
                        />
                    )}
                />
            }

        </React.Fragment >
    )

}

export default TreeSearch